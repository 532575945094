* {
  margin: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* @font-face {
  font-family: "SF-Pro-Display";
  font-weight: 600;
  src: local("SF-Pro-Display") local("SF-Pro-Display-Semibold"),
  url(/public/fonts/SF_Pro_Display/SFProDisplay-Semibold.ttf),
}
@font-face {
  font-family: "SF-Pro-Display";
  font-weight: 400;
  src: local("SF-Pro-Display") local("SF-Pro-Display-Regular"),
  url(/public/fonts/SF_Pro_Display/SFProDisplay-Regular.ttf),
} */
